import Vue from 'vue'
import VueRouter from 'vue-router'
import VueWechatTitle from 'vue-wechat-title';

Vue.use(VueWechatTitle)
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import( '../views/login.vue'),
        meta:{
            title:'松下软件市场（工厂端）'
        }//登入页面
    },

    {
        path: '/home',
        component: () => import( '../views/home.vue'), //后台主体框架
        redirect: '/softwareView/softwareShop',
        children: [
            {
                path: '/welcome',
                name: 'Welcome',
                component: () => import( '../views/welcome.vue'), //系统欢迎页
                meta:{
                    title:'松下软件市场（工厂端）'
                },
            },
            {
                path: '/softwareView/softwareShop',
                name: 'softwareShop',
                component: () => import( '../views/softwareView/softwareShop.vue'), //软件一览
                meta:{
                    title:'松下软件市场（工厂端）'
                },
            },
            {
                path: '/system/password',
                name: 'password',
                component: () => import( '../views/system/password.vue'), //修改密码
                meta:{
                    title:'松下软件市场（工厂端）'
                },
            },

            {
                path: '/environmentView/environment',
                name: 'environment',
                component: () => import( '../views/environmentView/environment.vue'), //环境设置
                meta:{
                    title:'松下软件市场（工厂端）'
                },
            },
            {
                path: '/softwareView/software',
                name: 'software',
                component: () => import( '../views/softwareView/software.vue'), //软件一览
                meta:{
                    title:'松下软件市场（工厂端）'
                },
            },
            {
                path: '/empoweringView/empowering',
                name: 'empowering',
                component: () => import( '../views/empoweringView/empowering.vue'), //端末一览
                meta:{
                    title:'松下软件市场（工厂端）'
                },
            },
            {
                path: '/applyView/apply',
                name: 'apply',
                component: () => import( '../views/applyView/apply.vue'), //申请一览
                meta:{
                    title:'松下软件市场（工厂端）'
                },
            },
            {
                path: '/ukeyView/ukey',
                name: 'ukey',
                component: () => import( '../views/ukeyView/ukey.vue'), //ukey一览
                meta:{
                    title:'松下软件市场（工厂端）'
                },
            }
        ]
    },
]


const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

import store from '../store'//引入store

//白名单页面
const whiteList=[
    "/system/password"
];

//路由导航守卫
router.beforeEach((to, from, next) => {

    const token = LocalStorage.get(LOCAL_KEY_ACCESS_TOKEN);
    if (to.path === '/login') {
        if (!token) {
            return next();
        } else {
            return next({path: '/home'})
        }
    }

    if (to.path === '/error/401') {
        return next();
    }

    let expireTime = LocalStorage.get("expiretime");
    if(expireTime != undefined && expireTime * 1 - new Date().getTime() > 0) {
        expireTime = new Date().getTime() + EXPIRETIME;
        LocalStorage.set("expiretime", expireTime);
    }
    let curTime = new Date().getTime();
    if (!token || expireTime != undefined && expireTime * 1 < curTime) {
        LocalStorage.clearAll();
        return router.push("/login");
    } else {
        return next();
    }
})


export default router
